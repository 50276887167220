<template>
<div class="h-screen flex w-full">
    <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
        <img src="@/assets/images/logo.png" class="mx-auto mb-2 max-w-full custom-logo-size">
        <!-- <h3 class="sm:mx-0 mx-4 mb-2 text-2xl text-white">Barrington Group Australia</h3> -->
        <div class="flex m-10 flex-wrap justify-between flex-col-reverse sm:flex-row mt-5">
            <vs-button @click="signin" size="large">Login</vs-button>
        </div>
    </div>
</div>
</template>

<script>
import * as Msal from "@azure/msal-browser";

import {
    BaseConfig
} from '../../store/api/config'

import {
    version
} from '../../../package.json';

export default {
    data() {
        return {
            currentVersion: "",
        }
    },
    created() {
        this.setupClientApplication();
    },
    methods: {
        signin() {
            var request = {
                redirectStartPage: `${BaseConfig.AppUrl}CompleteLogin`,
            }
            this.$store.dispatch("setIsProgressing", true);
            this.app.loginRedirect(request);
        },
        setupClientApplication() {
            this.app = new Msal.PublicClientApplication({
                auth: {
                    clientId: `${BaseConfig.AppClientID}`,
                    authority: `${BaseConfig.AppLoginAuthority}`,
                    // knownAuthorities: [`${BaseConfig.AppLoginAuthority}`],
                    // validateAuthority: false,
                    // navigateToLoginRequestUrl: true,
                    redirectUri: `${BaseConfig.AppUrl}CompleteLogin`,
                    // postLogoutRedirectUri: `${BaseConfig.AppUrl}login`,
                },
                cache: {
                    cacheLocation: "sessionStorage",
                    storeAuthStateInCookie: true,
                    secureCookies: true
                }
            });

            this.app.handleRedirectPromise().then(response => {
                if (response) {
                    this.$store.dispatch("setIsProgressing", true);
                    this.handleResponse(response);
                } else {
                    this.$store.dispatch("setIsProgressing", false);
                }
            }).catch(error => {
                this.$store.dispatch("setIsProgressing", false);
                this.loginInProgress = false;
            });
        },
        async handleResponse(response) {
            this.$store.dispatch("completeLogin", response.account);
        }
    }
}
</script>

<style scoped>
.custom-logo-size {
    width: 40rem;
}
</style>
